var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal-slots',{staticClass:"taksInfo-modal",attrs:{"is-pointer-bg":true,"no-close-after-apply":true,"show-mod":_vm.show,"textForSecondButton":'Open Task',"textForFirstButton":''},on:{"close":_vm.closeModal,"apply":_vm.goTotaskFull},scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.show)?_c('div',{staticClass:"task-name-wrapper"},[_c('p',{staticClass:"fz18 fw500"},[_vm._v(_vm._s(_vm.info.name))]),_c('span',{staticClass:"fz13"},[_vm._v(_vm._s(_vm._f("textTransform")(_vm.info.type)))])]):_vm._e()]},proxy:true}])},[(_vm.show)?_c('div',{staticClass:"modal-edit-clean-content task-info-wrapper flex flex-col"},[_c('div',{staticClass:"task-info-wrapper__row"},[_c('span',{staticClass:"row-title fz13 text-gray-text"},[_vm._v("Status")]),(_vm.info.status == 'Open')?_c('div',{staticClass:"status open"},[_vm._v("Open")]):_vm._e()]),_c('div',{staticClass:"task-info-wrapper__row"},[_c('span',{staticClass:"row-title fz13 text-gray-text"},[_vm._v("Created")]),_c('span',{staticClass:"date fz13"},[_vm._v(_vm._s(_vm.info.created_at))])]),_c('div',{staticClass:"task-info-wrapper__row"},[_c('span',{staticClass:"row-title fz13 text-gray-text"},[_vm._v("Due date")]),_c('span',{staticClass:"date fz13"},[_vm._v(_vm._s(_vm._f("textTransform")(_vm.info.due_date_status)))])]),(_vm.creator.isLoaded)?_c('div',{staticClass:"task-info-wrapper__row"},[_c('span',{staticClass:"row-title fz13 text-gray-text"},[_vm._v("Creator")]),_c('div',{staticClass:"user"},[(_vm.creator.creatorInfo.avatar)?_c('div',{staticClass:"avatar",style:({
            'background-image': ("url(" + (_vm.creator.creatorInfo.avatar) + ")"),
          })}):_c('div',{staticClass:"avatar"}),_c('div',{staticClass:"user-name fz13"},[_vm._v(" "+_vm._s(_vm.creator.creatorInfo.first_name || "")+" "+_vm._s(_vm.creator.creatorInfo.last_name || "")+" ")])])]):_vm._e(),(_vm.users.isLoaded)?_c('div',{staticClass:"task-info-wrapper__row"},[_c('span',{staticClass:"row-title fz13 text-gray-text"},[_vm._v("Assigned")]),_vm._l((_vm.users.list),function(user,index){return _c('div',{key:index,staticClass:"user"},[(user.avatar)?_c('div',{staticClass:"avatar",style:({ 'background-image': ("url(" + (user.avatar) + ")") })}):_c('div',{staticClass:"avatar"}),_c('div',{staticClass:"user-name fz13"},[_vm._v(" "+_vm._s(user.first_name || "")+" "+_vm._s(user.last_name || "")+" ")])])})],2):_vm._e(),(_vm.info.priority)?_c('div',{staticClass:"task-info-wrapper__row"},[_c('span',{staticClass:"row-title fz13 text-gray-text"},[_vm._v("Priority")]),_c('p',{staticClass:"task-block__prioity fz13"},[_c('span',{staticClass:"prioity high",class:{
            Urgent: _vm.info.priority == 5,
            high: _vm.info.priority == 4,
            Normal: _vm.info.priority == 3,
            Low: _vm.info.priority == 2,
            Low: _vm.info.priority == 1,
          }},[_c('svg',{attrs:{"width":"10","height":"12","viewBox":"0 0 10 12","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M9.85528 1.28944C9.92177 1.15646 9.82507 1 9.67639 1H1.5V0.75C1.5 0.335786 1.16421 0 0.75 0C0.335786 0 0 0.335786 0 0.75V11.25C0 11.6642 0.335786 12 0.75 12C1.16421 12 1.5 11.6642 1.5 11.25V9H9.67639C9.82507 9 9.92177 8.84354 9.85528 8.71056L8 5L9.85528 1.28944Z","fill":"#FF9035"}})]),(_vm.info.priority == 5)?_c('span',[_vm._v("Urgent")]):_vm._e(),(_vm.info.priority == 4)?_c('span',[_vm._v("high")]):_vm._e(),(_vm.info.priority == 3)?_c('span',[_vm._v("Normal")]):_vm._e(),(_vm.info.priority == 2)?_c('span',[_vm._v("Low")]):_vm._e(),(_vm.info.priority == 1)?_c('span',[_vm._v("Low")]):_vm._e()])])]):_vm._e(),(_vm.tags.isLoaded)?_c('div',{staticClass:"task-info-wrapper__row"},[_c('span',{staticClass:"row-title fz13 text-gray-text"},[_vm._v("Tag")]),_c('div',{staticClass:"tags-wrapper"},_vm._l((_vm.tags.list),function(tag,index){return _c('div',{key:index,staticClass:"tag-info fz11 "},[_vm._v(_vm._s(tag.name))])}),0)]):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }