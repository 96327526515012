<template>
  <modal-slots
    :is-pointer-bg="true"
    :no-close-after-apply="true"
    :show-mod="show"
    @close="closeModal"
    class="taksInfo-modal"
    @apply="goTotaskFull"
    :textForSecondButton="'Open Task'"
    :textForFirstButton="''"
  >
    <template v-slot:title>
      <div class="task-name-wrapper" v-if="show">
        <p class="fz18 fw500">{{ info.name }}</p>
        <span class="fz13">{{ info.type | textTransform }}</span>
      </div>
    </template>
    <div
      v-if="show"
      class="modal-edit-clean-content task-info-wrapper flex flex-col"
    >
      <div class="task-info-wrapper__row">
        <span class="row-title fz13 text-gray-text">Status</span>
        <div class="status open" v-if="info.status == 'Open'">Open</div>
      </div>
      <div class="task-info-wrapper__row">
        <span class="row-title fz13 text-gray-text">Created</span>
        <span class="date fz13">{{ info.created_at }}</span>
      </div>
      <div class="task-info-wrapper__row">
        <span class="row-title fz13 text-gray-text">Due date</span>
        <span class="date fz13">{{
          info.due_date_status | textTransform
        }}</span>
      </div>
      <div class="task-info-wrapper__row" v-if="creator.isLoaded">
        <span class="row-title fz13 text-gray-text">Creator</span>
        <div class="user">
          <div
            class="avatar"
            v-if="creator.creatorInfo.avatar"
            :style="{
              'background-image': `url(${creator.creatorInfo.avatar})`,
            }"
          ></div>
          <div class="avatar" v-else></div>
          <div class="user-name fz13">
            {{ creator.creatorInfo.first_name || "" }}
            {{ creator.creatorInfo.last_name || "" }}
          </div>
        </div>
      </div>
      <div class="task-info-wrapper__row" v-if="users.isLoaded">
        <span class="row-title fz13 text-gray-text">Assigned</span>
        <div class="user" v-for="(user, index) in users.list" :key="index">
          <div
            class="avatar"
            v-if="user.avatar"
            :style="{ 'background-image': `url(${user.avatar})` }"
          ></div>
          <div class="avatar" v-else></div>
          <div class="user-name fz13">
            {{ user.first_name || "" }} {{ user.last_name || "" }}
          </div>
        </div>
      </div>
      
      <div class="task-info-wrapper__row" v-if="info.priority">
        <span class="row-title fz13 text-gray-text">Priority</span>
        <p class="task-block__prioity fz13">
          <span
            class="prioity high"
            :class="{
              Urgent: info.priority == 5,
              high: info.priority == 4,
              Normal: info.priority == 3,
              Low: info.priority == 2,
              Low: info.priority == 1,
            }"
          >
            <svg
              width="10"
              height="12"
              viewBox="0 0 10 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.85528 1.28944C9.92177 1.15646 9.82507 1 9.67639 1H1.5V0.75C1.5 0.335786 1.16421 0 0.75 0C0.335786 0 0 0.335786 0 0.75V11.25C0 11.6642 0.335786 12 0.75 12C1.16421 12 1.5 11.6642 1.5 11.25V9H9.67639C9.82507 9 9.92177 8.84354 9.85528 8.71056L8 5L9.85528 1.28944Z"
                fill="#FF9035"
              />
            </svg>
            <span v-if="info.priority == 5">Urgent</span>
            <span v-if="info.priority == 4">high</span>
            <span v-if="info.priority == 3">Normal</span>
            <span v-if="info.priority == 2">Low</span>
            <span v-if="info.priority == 1">Low</span>
          </span>
        </p>
      </div>
      <div class="task-info-wrapper__row" v-if="tags.isLoaded">
        <span class="row-title fz13 text-gray-text">Tag</span>
        <div class="tags-wrapper">
          <div class="tag-info fz11 " v-for="(tag, index) in tags.list" :key="index">{{tag.name}}</div>
        </div>
      </div>
    </div>
  </modal-slots>
</template>
<script>
import ModalSlots from "@/components/modal/modal-slots";
import { mapActions } from "vuex";
export default {
  components: { ModalSlots },
  data: () => {
    return {
      show: false,
      info: null,
      users: {
        isLoaded: false,
        list: [],
      },
      creator: {
        isLoaded: false,
        creatorInfo: [],
      },
      tags: {
        isLoaded: false,
        list: [],
      }
    };
  },
  methods: {
    ...mapActions(["axiosGetEmployeeById", "getAxiosTagById"]),
    closeModal() {
      this.clear();
      this.show = false;
    },
    showModal(taskInfo) {
      this.info = taskInfo;
      this.show = true;
      this.getUserInfo();
      this.getCreatorInfo();
      this.getTags();
    },
    clear() {
      this.info = null;
      this.users.isLoaded = false;
      this.users.list = [];
      this.tags.isLoaded = false;
      this.tags.list = [];
      this.creator.isLoaded = false;
      this.creator.creatorInfo = [];
    },
    goTotaskFull() {
      this.$router.push({name: 'Task', params: {id: this.info.id}})
    },
    getUserInfo() {
      if (this.info.executors.length != 0) {
        this.info.executors.forEach(async (id) => {
          let user = await this.axiosGetEmployeeById(id);
          this.users.list.push(user);
        });
        this.users.isLoaded = true;
      }
    },
    getTags(){
      if (this.info.tags.length != 0) {
        this.info.tags.forEach(async (id) => {
          let tag = await this.getAxiosTagById(id);
          this.tags.list.push(tag);
        });
        console.log(this.tags.list)
        this.tags.isLoaded = true;
      }
    },
    async getCreatorInfo() {
      if (this.info.creator_id) {
        let user = await this.axiosGetEmployeeById(this.info.creator_id);
        this.creator.creatorInfo = user;
        this.creator.isLoaded = true;
      }
    },
  },
  filters: {
    textTransform: function (value) {
      let str = value.toLowerCase().split("_").join(" ");
      return str;
    },
  },
};
</script>

<style lang="scss">
.taksInfo-modal.wrap {
  .content {
    .top-panel {
      border: none;
    }
  }
}
</style>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.task-name-wrapper {
  width: 100%;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  span {
    color: var(--color-orange);
    padding-top: 2px;
    text-transform: capitalize;
  }
}
.task-info-wrapper {
  width: 100%;
  padding: 24px 24px 10px 24px;
  padding-top: 0;
  .task-info-wrapper__row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 12px;
  }
  .row-title {
    padding-bottom: 2px;
  }
  .status {
    color: #fff;
    padding: 2px 8px;
    border-radius: 10px;
    font-weight: 500;
    font-size: 13px;
    line-height: 100%;
    &.open {
      background: var(--color-green);
    }
  }
  .user {
    display: flex;
    align-items: center;
    padding-top: 6px;
  }
  .avatar {
    background-size: contain;
    background-position: center;
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    border-radius: 50%;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 0C5.37328 0 0 5.37222 0 11.9995C0 18.6267 5.37275 23.9989 12 23.9989C18.6278 23.9989 24 18.6267 24 11.9995C24 5.37222 18.6278 0 12 0ZM12 3.58798C14.1927 3.58798 15.9696 5.36537 15.9696 7.55706C15.9696 9.74928 14.1927 11.5261 12 11.5261C9.80831 11.5261 8.03145 9.74928 8.03145 7.55706C8.03145 5.36537 9.80831 3.58798 12 3.58798ZM11.9974 20.8616C9.81042 20.8616 7.80743 20.0652 6.2625 18.7469C5.88615 18.4259 5.66898 17.9552 5.66898 17.4613C5.66898 15.2385 7.46798 13.4595 9.69129 13.4595H14.3098C16.5336 13.4595 18.3257 15.2385 18.3257 17.4613C18.3257 17.9557 18.1096 18.4254 17.7328 18.7464C16.1884 20.0652 14.1848 20.8616 11.9974 20.8616Z' fill='%23DEE3EA'/%3E%3C/svg%3E%0A");
    margin-right: 6px;
  }
  .task-block__prioity {
    color: #785400;
    padding-bottom: 3px;
    display: flex;
    align-items: center;
    span {
      display: flex;
      align-items: center;
      svg {
        margin-right: 5px;
      }
      &.High {
        svg {
          path {
            fill: #f2994a;
          }
        }
      }
      &.Urgent {
        svg {
          path {
            fill: #e85353;
          }
        }
      }
      &.Normal {
        svg {
          path {
            fill: #0fa54e;
          }
        }
      }
      &.Low {
        svg {
          path {
            fill: #9aa3b0;
          }
        }
      }
    }
  }
  .tags-wrapper{
    display: flex;
    max-width: 320px;
    flex-wrap: wrap;
  }
  .tag-info{
    border: 1px solid var(--color-border);
    padding: 2px 6px;
    border-radius: 2px 10px 10px 2px;
    margin-right: 6px;
    margin-bottom: 3px;
  }
}
</style>