<template>
  <div class="week-table">
    <div
      class="day"
      :class="{
        today: getCurrentDay(day),
        'rounded-br-lg': day == 7,
        'rounded-bl-lg': day == 1,
      }"
      v-for="(day, i) in listDate"
      :key="i"
    >
      <div class="day-heading">
        <div class="day-name fz12 fw500 text-gray-text">
          {{ days[i] }}
        </div>
        <div class="day-number text-dark fz12">{{ day.index }}</div>
      </div>

      <div
        v-if="
          taskstsLoaded &&
          day.dayItems.tasks.length != 0 &&
          categoriesFilters.tasks &&
          getPermissionsByType('can_show_tasks_in_the_calendar')
        "
      >
       
        <div class="tasks-wrapper">
          <div
            class="tasks-wrapper__heading fz13 fw500"
            @click="($event) => { $event.target.classList.toggle('showTasks'); }">
            <span class="arrow">
              <svg
                width="8"
                height="5"
                viewBox="0 0 8 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.27557 4.00288C7.54284 4.00288 7.67669 3.67974 7.4877 3.49075L4.21196 0.215017C4.0948 0.0978596 3.90485 0.0978593 3.7877 0.215017L0.511973 3.49075C0.322984 3.67974 0.456834 4.00288 0.724105 4.00288L7.27557 4.00288Z"
                  fill="#2C2C2C"
                />
              </svg>
            </span>
            Tasks ({{day.dayItems.tasks.length}})
          </div>
          <div class="tasks-wrapper__content">
            <div v-for="(task, i) in day.dayItems.tasks" :key="i">
              <div class="task-block" @click="$refs.taskInfoModal.showModal(task)" :title="task.name">
                <p class="task-block__title fz13 fw500">{{ task.name }}</p>
                <p class="task-block__name fz13 no-plus">
                  {{getExecutors(task)}}
                </p>
                <p class="task-block__prioity fz13">
                  Prioity:
                  <span
                    class="prioity"
                    :class="{
                      Urgent: task.priority == 1,
                      high: task.priority == 2,
                      Normal: task.priority == 3,
                      Low: task.priority == 4,
                      Low: task.priority == 5,
                    }"
                  >
                    <svg
                      width="10"
                      height="12"
                      viewBox="0 0 10 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.85528 1.28944C9.92177 1.15646 9.82507 1 9.67639 1H1.5V0.75C1.5 0.335786 1.16421 0 0.75 0C0.335786 0 0 0.335786 0 0.75V11.25C0 11.6642 0.335786 12 0.75 12C1.16421 12 1.5 11.6642 1.5 11.25V9H9.67639C9.82507 9 9.92177 8.84354 9.85528 8.71056L8 5L9.85528 1.28944Z"
                        fill="#FF9035"
                      />
                    </svg>
                    <span v-if="task.priority == 1">Urgent</span>
                    <span v-if="task.priority == 2">high</span>
                    <span v-if="task.priority == 3">Normal</span>
                    <span v-if="task.priority == 4">Low</span>
                    <span v-if="task.priority == 5">Low</span>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        
      </div>

      <div
        v-if="
          paymentsLoaded &&
          day.dayItems.payments.length != 0 &&
          categoriesFilters.payments &&
          getPermissionsByType('can_show_payments_in_the_calendar')
        "
      >
        <div class="payments-wrapper">
          <div
            class="payments-wrapper__heading fz13 fw500"
            @click="($event) => { $event.target.classList.toggle('showPayments');}"
          >
            <span class="arrow">
              <svg
                width="8"
                height="5"
                viewBox="0 0 8 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.27557 4.00288C7.54284 4.00288 7.67669 3.67974 7.4877 3.49075L4.21196 0.215017C4.0948 0.0978596 3.90485 0.0978593 3.7877 0.215017L0.511973 3.49075C0.322984 3.67974 0.456834 4.00288 0.724105 4.00288L7.27557 4.00288Z"
                  fill="#2C2C2C"
                />
              </svg>
            </span>
            Payments ({{day.dayItems.payments.length}})
          </div>
          <div class="payments-wrapper__content">
            <div v-for="payment in day.dayItems.payments" :key="payment.id">
              <div
                class="payment-block"
                :class="{
                  expired: getExpiredPayment(day, payment),
                  payment: payment.payment_type == 'expense',
                  invoice: payment.payment_type == 'income',
                  paid: payment.status == 'paid',
                  not_paid: payment.status == 'not_paid',
                }"
                @click="$router.push(`/payment/list/detail/${payment.id}`)"
              >
                <p class="payment-block__name fz13">
                  <span>{{ payment.id }}: </span>
                  <span>{{ payment.account_name || "-" }}</span>
                </p>
                <p class="payment-block__status fz13">
                  {{ payment.client_name || "-" }}
                </p>
                <p class="payment-block__num fz13 fw500">
                  {{ payment.payment_amount }} {{ payment.currency }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="eventsLoaded && day.dayItems.events.length != 0 && categoriesFilters.events">
        <div v-for="(event, i) in day.dayItems.events" :key="i">
          <div class="event-block" @click="$refs.eventInfoModal.showModal(event, day)">
            <p class="fz13 fw500">
              {{ event.name }}
            </p>
            <p class="fz13" v-if="event.creatorInfo">
              {{ event.creatorInfo.first_name || '' }}
              {{ event.creatorInfo.last_name || '' }}
            </p>
            <p class="fz13">{{ event.from_time | formatTime }} - {{ event.to_time | formatTime }}</p>
          </div>
        </div>
      </div>
    </div>
    <taskInfoModal ref="taskInfoModal"></taskInfoModal>
    <eventInfoModal @delEvent="deleteEvent($event)" ref="eventInfoModal"></eventInfoModal>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import moment from "moment";
import taskInfoModal from "@/components/calendar/taskInfoModal";
import eventInfoModal from "@/components/calendar/eventInfoModal";

export default {
  components: {
    taskInfoModal,
    eventInfoModal
  },
  data: () => {
    return {
      days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      date: new Date(),
      today: new Date().getDate(),
      tasks: [],
      payments: [],
      events: [],
      startWeek: null,
      endWeek: null,
      taskstsLoaded: false,
      paymentsLoaded: false,
      eventsLoaded: false,
      categoriesFilters: {
        filters: {
          payments: true,
          tasks: true,
          events: true,
        },
      },
      selectDate: new Date(),
      before: "",
      after: "",
      listDate: [],
    };
  },
  methods: {
    ...mapMutations(["showLoad", "hideLoad"]),
    ...mapActions([
      "axiosGetCalendarEventsList",
      "axiosGetCalendarTasksList",
      "axiosGetCalendarPaymentsList",
      "axiosGetEmployeeById",
      "axiosDeleteCalendarEvent"
    ]),
    ...mapGetters([
      "getCalendarEventsList",
      "getCalendarTasksList",
      "getCalendarPaymentsList",
      "getCalendarCategoriesFilter",
      "getCalendarMembersList"
    ]),
    async createCalendar() {
      this.selectDate = this.date;
      let date = this.selectDate;
      let now = moment(date);
      let date1 = now.clone().weekday(0);
      let date2 = now.clone().weekday(6);
      let start = date1._d;
      let end = date2._d;
      this.startWeek = start;
      this.endWeek = end;
      let listDate = [];
      let whileEnd = true;

      this.showLoad();
      await this.getTasks({
        date_after: moment(this.startWeek)
          .format("YYYY.MM.DD")
          .split(".")
          .join("-"),
        date_before: moment(this.endWeek)
          .format("YYYY.MM.DD")
          .split(".")
          .join("-"),
        // limit: 5,
        is_archived: false,
        // only_for_me: true
      });
      await this.getEvents({
        date_after: moment(this.startWeek)
          .format("YYYY.MM.DD")
          .split(".")
          .join("-"),
        date_before: moment(this.endWeek)
          .format("YYYY.MM.DD")
          .split(".")
          .join("-"),
      });
      await this.getPayments({
        due_date_after: moment(this.startWeek)
          .format("YYYY.MM.DD")
          .split(".")
          .join("-"),
        due_date_before: moment(this.endWeek)
          .format("YYYY.MM.DD")
          .split(".")
          .join("-"),
        // ship_null: true,
        is_archive: false
      });


      do {
        if (
          start.getDate() === end.getDate() &&
          start.getMonth() === end.getMonth() &&
          start.getFullYear() === end.getFullYear()
        ) {
          whileEnd = false;
        }
        let form = {
          date: start,
          index: new Date(start).getDate(),
          dayName: this.days[new Date(start).getDay()],
          dayItems: {
            tasks: this.getTaskInDay(start, this.days[new Date(start).getDay()]),
            payments: this.getPaimentInDay(start),
            events: this.getEventInDay(start, this.days[new Date(start).getDay()]),
          },
        };
        listDate.push(form);
        start = moment(start).add(1, "day")._d;
      } while (whileEnd);
      this.listDate = listDate;

      this.hideLoad();
    },
    async getEvents(filter = {}) {
      let events = await this.axiosGetCalendarEventsList(filter);
      this.events = events.sort((a, b) => {
        if(moment(`${a.from_time}`, "HH:mm:ss").isBefore(moment(`${b.from_time}`, "HH:mm:ss"))){
          return -1;
        }else{
          return 1;
        }
      });
      // this.events = events;
      let storeMembersList = await this.getCalendarMembersList()
      this.events.forEach((element) => {
        element.creatorInfo = storeMembersList.results.find(member => member.id === element.creator);
      });
      this.eventsLoaded = true;
    },
    async getTasks(filter = {}) {
      let tasks = await this.axiosGetCalendarTasksList(filter);
      this.tasks = tasks;
      // this.tasks.results.forEach((element) => {
      //   element.date = new Date(element.created_at).getDate();
      // });
      this.taskstsLoaded = true;
      // return tasks;
    },
    async getPayments(filter = {}) {
      let payments = await this.axiosGetCalendarPaymentsList(filter);
      this.payments = payments;
      // this.payments.results.forEach((element) => {
      //   element.date = new Date(element.execute_date).getDate();
      // });
      this.paymentsLoaded = true;
      // return payments;
    },
    getExpiredPayment(day, payment) {
      return day.date.getTime() > new Date(payment.paid_date).getTime();
    },
    getCurrentDay(day) {
      let dayDate = day.date.toLocaleDateString("en-US");
      let currentDate = new Date().toLocaleDateString("en-US");
      if (currentDate == dayDate) {
        return true;
      } else {
        return false;
      }
    },
    deleteEvent(id) {
      this.axiosDeleteCalendarEvent(id)
        .then(() => {
          this.createCalendar()
        })
        .catch(() => {
          this.createCalendar()
        });
    },
    dateMatches(date1, date2){
      if(new Date(date1).toLocaleDateString("en-US") == new Date(date2).toLocaleDateString("en-US")){
        return true;
      }else{
        return false;
      }
    },
    // Определить дату начала повторяющихся событий события
    startDate(currentDate, createdDate){
      if(moment(currentDate) >= moment(createdDate)){
        return true;
      }else{
        return false;
      }
    },
    endDate(currentDate, endDate){
      if(endDate){
        if(moment(currentDate) > moment(endDate)){
          return false;
        }else{
          return true;
        }
      }else{
        return true
      }
    },
    isExcludeDate(event, day){
      let dateFormatting = moment(day).format("YYYY.MM.DD").split(".").join("-");
      if(!event.exclude_days){
        return true;
      }else{
        if(event.exclude_days.includes(dateFormatting)){
          return false;
        }else{
          return true;
        }
      }
    },
    getTaskInDay(dayDate, dayName) {
      if(this.taskstsLoaded && this.tasks.results.length != 0){
        let arr = this.tasks.results.filter(task => {
          if(task.due_date_status == 'every_day_repeat' && this.startDate(dayDate, task.created_at)){
            return task
          }else if(task.due_date_status == 'every_workday_repeat' && !['Sun', 'Sat'].includes(dayName) && this.startDate(dayDate, task.created_at)){
            return task
          }else if(task.due_date_status == 'due_date' && this.dateMatches(task.execute_time, dayDate)){
            return task;
          }else if(task.due_date_status == 'custom_days_repeat' && task.days_for_repeat.includes(dayDate.getDate())){
            return task;
          }
        });
        return arr;
      }else{
        return [];
      }
    },
    getPaimentInDay(dayDate) {
      if(this.paymentsLoaded && this.payments.results.length != 0){
        let arr = this.payments.results.filter(el => {
          if(this.dateMatches(el.execute_date, dayDate)) return el
        });
        return arr
      }else{
        return [];
      }
    },
    getEventInDay(dayDate, dayName) {
      
      if(this.eventsLoaded && this.events.length != 0){
        let arr = this.events.filter(event => {
          if(event.repeat ==  'daily' && this.startDate(dayDate, event.date) && this.isExcludeDate(event, dayDate) && this.endDate(dayDate, event.end_repeat_day)){
            return event;
          }else if(event.repeat ==  'every_workday_repeat' && !['Sun', 'Sat'].includes(dayName) && this.startDate(dayDate, event.date) && this.isExcludeDate(event, dayDate) && this.endDate(dayDate, event.end_repeat_day)){
            return event;
          }else if(event.repeat == 'do_no_repeat' && this.dateMatches(event.date, dayDate)){
            return event;
          }else if(event.repeat == 'custom_days_repeat' && this.startDate(dayDate, event.date) && this.isExcludeDate(event, dayDate)){
            let dateFormatting = moment(dayDate).format("YYYY.MM.DD").split(".").join("-");
            if(Array.isArray(event.days_for_repeat) && event.days_for_repeat.length){
              if(event.days_for_repeat.includes(dateFormatting) || this.dateMatches(event.date, dayDate)) return event
            }
          }
        });
        return arr;
      }else{
        return [];
      }
    },
    getExecutors(task){
      if(Array.isArray(task.executors) && task.executors.length){
        let member = this.getCalendarMembersList().results.find(el => el.id == task.executors[0]);
        if(task.executors.length > 1){
          if(member){
            return `${member.name} (+${task.executors.length - 1})`
          }
        }else{
          return `${member.name}`
        }
      }
      return `-`
      
    },
  },
  // mounted() {
  //   this.startComponent();
  // },
  activated() {
    this.categoriesFilters = this.getCalendarCategoriesFilter();
    this.createCalendar()
    this.$myEmitter.on("setCategoryFilter", () => {
      this.categoriesFilters = this.getCalendarCategoriesFilter();
      this.createCalendar()
    });
    this.$myEmitter.on("lodadNewData", (date) => {
      this.date = date;
      this.createCalendar()
    });
    this.$myEmitter.on("loadFilteredEvents", async () => {
      // this.showLoad();
      this.createCalendar()
      // await this.getEvents({
      //   date_after: moment(this.startWeek)
      //     .format("YYYY.MM.DD")
      //     .split(".")
      //     .join("-"),
      //   date_before: moment(this.endWeek)
      //     .format("YYYY.MM.DD")
      //     .split(".")
      //     .join("-"),
      // });
      // this.hideLoad();
    });
  },
  deactivated() {
    this.$myEmitter.off("lodadNewData");
    this.$myEmitter.off("loadFilteredEvents");
    this.$myEmitter.off("setCategoryFilter");
  },
  computed: {
    ...mapGetters(['getPermissionsByType'])
  },
  filters: {
    formatTime: function(value){
      return moment(value, "HH:mm:ss").format('HH:mm');
    }
  }
};
</script>
<style lang="scss" scoped>
.week-table {
  flex-grow: 1;
  display: flex;
  .day {
    width: calc(100% / 7);
    border-right: 1px solid var(--color-card-border);
    display: flex;
    flex-direction: column;
    &:last-child {
      border: none;
    }
    &.today {
      border: 1px solid black;
    }
  }
}
.day-heading {
  height: 48;
  display: flex;
  flex-direction: column;
  .day-name {
    height: 24px;
    border-bottom: 1px solid var(--color-card-border);
    padding-left: 9px;
    display: flex;
    align-items: center;
  }
  .day-number {
    height: 24px;
    padding-left: 9px;
    display: flex;
    align-items: center;
  }
}
.task-block {
  height: 64px;
  background: #ffedc3;
  margin-bottom: 2px;
  padding: 4px 9px;
  display: flex;
  flex-direction: column;
  .task-block__title,
  .task-block__prioity {
    color: #785400;
    padding-bottom: 3px;
    display: inline-flex;
    align-items: center;
    overflow: hidden;
    span {
      display: flex;
      align-items: center;
      margin-left: 5px;
      svg {
        margin-right: 5px;
      }
      &.High {
        svg {
          path {
            fill: #f2994a;
          }
        }
      }
      &.Urgent {
        svg {
          path {
            fill: #e85353;
          }
        }
      }
      &.Normal {
        svg {
          path {
            fill: #0fa54e;
          }
        }
      }
      &.Low {
        svg {
          path {
            fill: #9aa3b0;
          }
        }
      }
    }
  }
  .task-block__title{
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline;
  }
  .name-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 3px;
    .num {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px 3px;
      border-radius: 50%;
      border: 1px solid #785400;
      margin-left: 5px;
      font-size: 11px;
      line-height: 13px;
    }
  }
  .task-block__name {
    color: #785400;
    display: flex;
    align-items: center;
    max-width: 85%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
  }
  .task-block__name:not(.no-plus):after {
    content: "+" attr(data-num);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 3px;
    border-radius: 50%;
    border: 1px solid #785400;
    margin-left: 5px;
    font-size: 11px;
    line-height: 13px;
  }
}
.tasks-wrapper {
  .tasks-wrapper__heading {
    height: 32px;
    background: #ffedc3;
    border: 1px solid #fff;
    padding: 4px 3px 9px;
    display: flex;
    color: #785400;
    align-items: center;
    .arrow {
      width: 18px;
      margin-right: 3px;
      display: flex;
      justify-content: center;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .tasks-wrapper__content {
    height: 0;
    overflow: hidden;
  }
  .showTasks{
    .arrow{
      transform-origin: center;
      transform: rotate(180deg);
    }
  }
  .tasks-wrapper__heading.showTasks + .tasks-wrapper__content {
    height: auto;
  }
}
.payment-block {
  height: 64px;
  background: #f4f6f9;
  margin-bottom: 2px;
  padding: 1px 6px;
  display: flex;
  flex-direction: column;
  border: 3px solid #f4f6f9;
  justify-content: center;

  .payment-block__status,
  .payment-block__num {
    margin-bottom: 4px;
    // display: flex;
    // align-items: center;
    display: inline;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    padding-left: 18px;
    &:before {
      content: "";
      display: flex;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: #dee3ea;
      margin-right: 10px;
      position: absolute;
      left: 4px;
      top: 5px;
    }
  }
  .payment-block__name {
    margin-bottom: 4px;
    display: inline;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    padding-left: 18px;
    span{
      max-width: 50%;
      display: inline;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  &.expired {
    border-left-color: #e85353;
  }
  &.payment {
    .payment-block__name {
      &:before {
        content: "";
        display: flex;
        margin-right: 10px;
        position: absolute;
        left: 0;
        top: 5px;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 7px solid transparent;
      }
    }
    &.not_paid{
      .payment-block__name{
        &:before{
          border-top-color: #E85353;
        }
      }
    }
  }
  &.invoice {
    border-left-color: transparent;
    .payment-block__name {
      &:before {
        content: "";
        display: flex;
        //width: 12px;
        //height: 8px;
        margin-right: 10px;
        position: absolute;
        left: 0;
        top: 5px;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 7px solid red;
        //transform: rotateX(180deg);
        // background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.43363 0H9.56637C10.79 0 11.4988 1.38624 10.7824 2.37824L7.21602 7.31628C6.61728 8.1453 5.38272 8.1453 4.78398 7.31628L1.21762 2.37824C0.501177 1.38625 1.20998 0 2.43363 0Z' fill='%239AA3B0'/%3E%3C/svg%3E%0A");
      }
    }
    &.not_paid{
      .payment-block__name{
        &:before{
          border-bottom-color: #0FA54E;
        }
      }
    }
  }
  &.paid {
    .payment-block__name{
      &:before{
        border-top-color: #9AA3B0;
        border-bottom-color: #9AA3B0;
      }
    }
  }
}
.payments-wrapper {
  .payments-wrapper__heading {
    height: 32px;
    background: #f4f6f9;
    border: 1px solid #fff;
    padding: 4px 3px 9px;
    display: flex;
    color: #785400;
    align-items: center;
    .arrow {
      width: 18px;
      margin-right: 3px;
      display: flex;
      justify-content: center;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .payments-wrapper__content {
    height: 0;
    overflow: hidden;
  }
  .showPayments{
    .arrow{
      transform-origin: center;
      transform: rotate(180deg);
    }
  }
  .payments-wrapper__heading.showPayments + .payments-wrapper__content {
    height: auto;
  }
}
.event-block {
  height: 64px;
  background: #e5dbff;
  margin-bottom: 2px;
  padding: 1px 6px;
  display: flex;
  flex-direction: column;
  border: 1px solid #fff;
  justify-content: center;
}
</style>

